<template>
  <b-overlay
    :show="applyOverlay"
    opacity="1"
    :class="applyOverlay ? 'p-2 mb-1 mt-1' : ''"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <div v-if="infoForm">
      <b-row>
        <b-col
          v-if="!editFormSI"
          sm="12"
        >
          <span class="float-right mt-1">
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="editFormSI = true"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <validation-observer ref="infoValidations">
        <b-form @submit.prevent="saveInformation">
          <b-row>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="phone">
                {{ $t('accountSettings.phone') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required|min:10"
                >
                  <cleave
                    id="phone"
                    v-model="infoForm.phone"
                    class="form-control"
                    :raw="false"
                    :state="errors.length > 0 ? false : null"
                    :options="clevePhone"
                    :placeholder="$t('accountSettings.placeholder.enterPhone')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="mobile-phone">
                {{ $t('accountSettings.mobilePhone') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="mobilePhone"
                  rules="required|min:10"
                >
                  <cleave
                    id="mobile-phone"
                    v-model="infoForm.cellPhone"
                    class="form-control"
                    :raw="false"
                    :state="errors.length > 0 ? false : null"
                    :options="clevePhone"
                    :placeholder="$t('accountSettings.placeholder.enterPhone')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <b-form-group label-for="address">
                {{ $t('accountSettings.address') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required|addressRegex|min:10|max:255"
                >
                  <b-form-input
                    id="address"
                    v-model="infoForm.address.address"
                    :state="errors.length > 0 ? false : null"
                    name="address"
                    :placeholder="$t('accountSettings.placeholder.enterAddress')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="zip-code">
                {{ $t('accountSettings.zipCode') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="postalCode"
                  rules="required|zipRegex|min:2|max:5"
                >
                  <b-form-input
                    id="zip-code"
                    v-model="infoForm.address.zipCode"
                    :state="errors.length > 0 ? false : null"
                    name="postal-code"
                    :placeholder="$t('accountSettings.placeholder.enterZipCode')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="countries">
                {{ $t('generic.country') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-select
                    id="countries"
                    v-model="infoForm.address.countryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :reduce="val => val.id"
                    :options="countries"
                    :placeholder="$t('generic.selectCountry')"
                    :disabled="!editFormSI"
                    @input="fetchStates(infoForm.address.countryId)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="states">
                {{ $t('generic.state') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="state"
                  rules="required"
                >
                  <v-select
                    id="states"
                    v-model="infoForm.address.stateId"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :reduce="val => val.id"
                    :options="states"
                    :placeholder="$t('generic.selectState')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="data-nationality">
                {{ $t('generic.nationality') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="nationality"
                  rules="required"
                >
                  <v-select
                    id="data-nationality"
                    v-model="infoForm.nationalityId"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :reduce="val => val.id"
                    :options="nationalities"
                    :placeholder="$t('generic.selectNationality')"
                    :disabled="!editFormSI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <upload-file
                :title="$t('member.form.label.passport')"
                folder="users"
                :disabled="!editFormSI"
                :club-id="''"
                :file-title="infoForm.passportFile"
                @uploaded-file="assignFilePassport($event)"
              />
            </b-col>
            <b-col cols="12">
              <hr>
              <b-button
                v-if="editFormSI"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                pill
                variant="primary"
                class="ml-1 float-right"
              >
                {{ $t('buttons.save') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="secondary"
                pill
                class="float-right"
                @click="editFormSI ? cancelAndReassignData() : $router.push({ name: 'dashboard-main'})"
              >
                {{ editFormSI ? $t('buttons.cancel') : $t('buttons.back') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions, mapGetters } from 'vuex'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, zipRegex, addressRegex, min, max } from '@validations'
import checkPermission from '@/auth/permissions'
import { destroyMediaFile } from '@/services/mediaFileService'
import { nationalities } from '@/services/catalogsService'
import userService from '@/services/userService'
import countryService from '@/services/countryService'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import UploadFile from '@/views/member/components/UploadFile.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    UploadFile,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      statesList: [],
      countriesList: [],
      nationalitiesList: [],
      previousFileHash: null,
      applyDeleteFile: false,
      applyOverlay: false,
      editFormSI: false,
      auxForm: null,
      infoForm: {
        id: null,
        phone: null,
        cellPhone: null,
        passportFile: null,
        passportFileId: null,
        nationalityId: null,
        address: {
          address: null,
          countryId: null,
          stateId: null,
          zipCode: null,
        },
      },
      required, // validation
      zipRegex, // validation
      addressRegex, // validation
      min, // validation
      max, // validation
      clevePhone: {
        phone: true,
        phoneRegionCode: 'MX',
      },
    }
  },

  computed: {
    ...mapGetters({
      incomplete: 'incomplete',
      incompleteByImage: 'incompleteByImage',
      updatedPassword: 'updatedPassword',
      path: 'path',
    }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.statesList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    nationalities() {
      const list = this.nationalitiesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
      this.fetchNationalities()
    },
  },

  mounted() {
    this.fetchCountries()

    if (this.incomplete) {
      this.showIncompleteDataMessage()
      this.editFormSI = true
    }

    this.fetchUserInfo()
  },

  methods: {
    checkPermission,

    ...mapActions({ setInfo: 'setInfo' }),

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      this.infoForm.address.stateId = null
      const filtersObj = { country_hash: id }

      countryService.getStates(filtersObj).then(({ data }) => {
        this.statesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchNationalities() {
      nationalities().then(({ data }) => {
        this.nationalitiesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchUserInfo() {
      this.applyOverlay = true
      userService.getUserInfo(this.$route.params.id).then(({ data }) => {
        if (data.data.address != null && data.data.address.country_hash != null) {
          this.fetchStates(data.data.address.country_hash)
        }

        this.infoForm = {
          id: data.data.hash,
          phone: data.data.phone,
          cellPhone: data.data.cell_phone,
          passportFile: data.data.passport_media_file == null ? null : data.data.passport_media_file.title,
          passportFileId: data.data.passport_media_file == null ? null : data.data.passport_file_hash,
          nationalityId: data.data.nationality_hash != 0 ? data.data.nationality_hash : null,
          address: {
            address: (data.data.address != null) ? data.data.address.address : null,
            countryId: (data.data.address != null) ? data.data.address.country_hash : null,
            stateId: (data.data.address != null) ? data.data.address.state_hash : null,
            zipCode: (data.data.address != null) ? data.data.address.zip_code : null,
          },
        }

        this.auxForm = JSON.parse(JSON.stringify(this.infoForm))
        this.applyOverlay = false
      }).catch(error => {
        this.applyOverlay = true
        this.responseCatch(error)
      })

      this.fetchNationalities()
    },

    cancelAndReassignData() {
      this.infoForm = JSON.parse(JSON.stringify(this.auxForm))
      this.editFormSI = false
    },

    saveInformation() {
      this.$refs.infoValidations.validate().then(success => {
        if (success) {
          const userDataX = {
            id: this.infoForm.id,
            phone: this.infoForm.phone,
            cell_phone: this.infoForm.cellPhone,
            passport_file: this.infoForm.passportFile,
            passport_file_id: this.infoForm.passportFileId,
            nationality_id: this.infoForm.nationalityId,
            address: {
              address: this.infoForm.address.address,
              country_id: this.infoForm.address.countryId,
              state_id: this.infoForm.address.stateId,
              zip_code: this.infoForm.address.zipCode,
            },
          }

          userService.updateUserInfo(this.infoForm.id, userDataX).then(() => {
            if (this.incomplete) {
              this.refreshDataUser()
              this.applyDeleteFile ? this.deleteFileX() : this.showSuccessMessage(false)
              // const params = {
              //   type: 2,
              // }
              //
              // this.updateUserAccount(params).then(response => {
              //   this.showSuccessMessage(false)
              // })
            } else this.showSuccessMessage(true)
          }).catch(error => {
            this.responseCatch(error)
          })
        }
      })
    },

    assignFilePassport(responseFile) {
      if (this.infoForm.passportFileId == null) {
        this.infoForm.passportFile = responseFile
        this.infoForm.passportFileId = responseFile.hash
      } else {
        this.previousFileHash = this.infoForm.passportFileId
        this.applyDeleteFile = true
        this.infoForm.passportFile = responseFile.title
        this.infoForm.passportFileId = responseFile.hash
      }
    },

    deleteFileX() {
      if (this.previousFileHash != null) {
        destroyMediaFile(this.previousFileHash).then(response => {
          this.showSuccessMessage(false)
        }).catch(error => {
          this.responseCatch(error)
        })
      } else this.showSuccessMessage(false)
    },

    refreshDataUser() {
      const data = { locale: null }

      userService.getUserAuthenticated(data).then(response => {
        this.setInfo({ user: response.data.data, permissions: null })
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    showSuccessMessage(pFlag) {
      this.$swal({
        title: '¡Éxito!',
        text: 'La información se guardo correctamente',
        icon: 'success',
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

      if (pFlag) {
        this.fetchUserInfo()
        this.editFormSI = false
      } else {
        setTimeout(() => {
          if (this.incompleteByImage) {
            this.$router.push({ name: 'ac-general-info', params: { id: this.$route.params.id } })
          } else if (!this.updatedPassword) {
            this.$router.push({ name: 'ac-change-password', params: { id: this.$route.params.id } })
          } else {
            this.$router.push({ name: 'dashboard-main' })
          }
        }, 1600)
      }
    },

    showIncompleteDataMessage() {
      this.$swal({
        title: '¡Tu información es incompleta!',
        text: 'Para poder continuar es necesario concluir tu registro.',
        icon: 'warning',
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
